// 
// preloader.scss
//

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $card-bg;
    z-index: 9999;
}

#status {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}
    
.spinner {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid #{map-get($grays, "300")};
    border-right: 5px solid #{map-get($grays, "300")};
    border-bottom: 5px solid #{map-get($grays, "300")};
    border-left: 5px solid $primary;
    transform: translateZ(0);
    animation: SpinnerAnimation 1.1s infinite linear;
}

.spinner,
.spinner:after {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

@keyframes SpinnerAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


// dark mode
body[data-layout-color="dark"] {
    .spinner {
        border-top: 5px solid #{map-get($dark-grays, "300")};
        border-right: 5px solid #{map-get($dark-grays, "300")};
        border-bottom: 5px solid #{map-get($dark-grays, "300")};
    }
}